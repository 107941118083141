
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

import { Internet } from '../../pages/Internet/ViewMore';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type SeeList = {
  list: string[] | undefined;
  value: number | undefined;
};

//Aqui estou fazendo um novo dialog somente para mostrar um array dos valores de referencia
//do ipv4
export function Details({ list, value }: SeeList) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {value}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Parâmetro(s) Esperados"}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: "flex", flexDirection: "column", gap: ".7rem", alignItems: "center" }}>
              {list?.map((item) => (
                <p style={{ margin: 0 }}>{item}</p>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


type Props = {
  dataRow: Internet | undefined;
};

function createData(
  param: string,
  configured?: number | string,
  expect?: (string | undefined)[],
  listExpect?: string[] | undefined,
  status?: string,
  history?: string[] | number[]
) {
  return {
    param,
    configured,
    expect,
    listExpect,
    status,
    history
  };
}


function Row(props: { row: ReturnType<typeof createData>; }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {row && <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {row.history && <>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>}
          </TableCell>
          <TableCell component="th" scope="row">
            <p>{row.param}</p>
            <p></p>
          </TableCell>
          <TableCell align="center">{row.configured}</TableCell>
          <TableCell align='center'>
            {row.expect && row.expect.length === 1 ? <>{row.listExpect}</> :
              <><Details list={row.listExpect} value={row.expect?.length} /></>}
          </TableCell>
          <TableCell align="center">
            <Badge>
              {row.status === "passed" ? <CheckIcon color='success' /> : row.status === "warning" ? <ErrorOutlineIcon sx={{ color: "orange" }} />
                : <CloseIcon sx={{ color: "red" }} />}
            </Badge>
          </TableCell>
        </TableRow>
        {row.history && <>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Detalhes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.history?.length > 0 ? row.history.map((historyRow, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {historyRow}
                          </TableCell>
                        </TableRow>
                      )) : <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Sem Alertas
                          </TableCell>
                        </TableRow></>}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>}
      </>}
    </React.Fragment>
  );
}

export default function CollapsibleTable({ dataRow }: Props) {

  const rotas = [dataRow?.wanInet?.status_rotas_ref];
  const hguRx = [dataRow?.wanOmci?.hgu_rx_value_ref];
  const hguTx = [dataRow?.wanOmci?.hgu_tx_value_ref];

  const rows = [
    //O primeiro e o nome do parametro seguido do o tecnico confugrou depois o que era esperado
    //se o que era esperado tiver mais de um elemento ele pode receber uma lista de strings
    //ele pega o tamanho da string e mostra num botão clicavel que mostrara todos os elementos esperados
    // e por ultimo uma lista que pode ou não existir, nela terá detalhes ou alarmes se existir
    // criara um botão logo no inicio para expandir a linha

    createData('Alerts Dns', "---", ['---'], ['---'], "warning", dataRow?.wanDns?.alerts),
    createData('Dns IPv4', dataRow?.wanDns?.dns_ipv4, dataRow?.wanDns?.dns_ipv4_ref, dataRow?.wanDns?.dns_ipv4_ref, dataRow?.wanDns?.dns_ipv4_validation),
    createData('DNS IPv6', dataRow?.wanDns?.dns_ipv6, dataRow?.wanDns?.dns_ipv6_ref, dataRow?.wanDns?.dns_ipv6_ref, dataRow?.wanDns?.dns_ipv6_validation),
    createData('Teste Facebook IPv4', dataRow?.wanDns?.test_facebook_ipv4, dataRow?.wanDns?.test_facebook_ipv4_ref, dataRow?.wanDns?.test_facebook_ipv4_ref, dataRow?.wanDns?.test_facebook_ipv4_validation),
    createData('Teste Facebook IPv6', dataRow?.wanDns?.test_facebook_ipv6, dataRow?.wanDns?.test_facebook_ipv6_ref, dataRow?.wanDns?.test_facebook_ipv6_ref, dataRow?.wanDns?.test_facebook_ipv6_validation),
    createData('Teste Google IPv4', dataRow?.wanDns?.test_google_ipv4, dataRow?.wanDns?.test_google_ipv4_ref, dataRow?.wanDns?.test_google_ipv4_ref, dataRow?.wanDns?.test_google_ipv4_validation),
    createData('Teste Google IPv6', dataRow?.wanDns?.test_google_ipv6, dataRow?.wanDns?.test_google_ipv6_ref, dataRow?.wanDns?.test_google_ipv6_ref, dataRow?.wanDns?.test_google_ipv6_validation),
    createData('Alerts Inet', "---", ['---'], ['---'], "warning", dataRow?.wanInet?.alerts),
    createData("Status Iface", dataRow?.wanInet?.status_iface, dataRow?.wanInet?.status_iface_ref, dataRow?.wanInet?.status_iface_ref, dataRow?.wanInet?.status_iface_validation),
    createData("Status IPv4", dataRow?.wanInet?.status_ipv4, dataRow?.wanInet?.status_ipv4_ref, dataRow?.wanInet?.status_ipv4_ref, dataRow?.wanInet?.status_ipv4_validation),
    createData("Status IPv6", dataRow?.wanInet?.status_ipv6, dataRow?.wanInet?.status_ipv6_ref, dataRow?.wanInet?.status_ipv6_ref, dataRow?.wanInet?.status_ipv6_validation),
    createData("Rotas", dataRow?.wanInet?.status_rotas, rotas, [rotas.toString()], dataRow?.wanInet?.status_rotas_validation),
    createData("VLAN", dataRow?.wanInet?.vlan, dataRow?.wanInet?.status_vlan_ref, dataRow?.wanInet?.status_vlan_ref, dataRow?.wanInet?.status_vlan_validation),
    createData('Alerts OMCI', "---", ['---'], ['---'], "warning", dataRow?.wanOmci?.alerts),
    createData("HGU RX", dataRow?.wanOmci?.hgu_rx, hguRx, [hguRx.toString()], dataRow?.wanOmci?.hgu_rx_validation),
    createData("HGU TX", dataRow?.wanOmci?.hgu_tx, hguTx, [hguTx.toString()], dataRow?.wanOmci?.hgu_tx_validation),
    createData("Modal Bosa", dataRow?.wanOmci?.model_bosa, dataRow?.wanOmci?.model_bosa_ref, dataRow?.wanOmci?.model_bosa_ref, dataRow?.wanOmci?.model_bosa_validation),
  ];
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Parâmetros</TableCell>
            <TableCell align="center">Configurado</TableCell>
            <TableCell align="center">Esperado</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.param} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}