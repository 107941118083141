import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import InternetTable from '../../components/TablesInternet/InternetTable';
import Wifi5GPage from '../../components/TablesInternet/Wifi5G';
import Wifi5GBSPage from '../../components/TablesInternet/Wifi5GBS';
import Wifi2GPage from '../../components/TablesInternet/Wifi2G';
import SmartHome from '../../components/TablesInternet/SmartHome';
import RedeLocal from '../../components/TablesInternet/RedeLocal';
import VoIp from '../../components/TablesInternet/VoIp';

import {
  CertWanDns,
  CertWanOmci,
  CertWanInet,
  CertWifi5G,
  CertWifi5GBS,
  CertWifi2G,
  CertWifiSmartHome,
  CertLanIface,
  CertLanDns,
  CertVoip,
  Certificado
} from '../../interfaces/BandaLarga';


import { bandaLargaCert } from '../../services/api';
import Internet from './Internet';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


type Props = {
  title: string;
  protocolo: string | undefined;
};

export type Internet = {
  wanOmci: CertWanOmci | undefined,
  wanDns: CertWanDns | undefined,
  wanInet: CertWanInet | undefined;
};

export type Lan = {
  lanIface: CertLanIface | undefined,
  lanDns: CertLanDns | undefined;

};



export default function AlertDialogSlide({ title, protocolo }: Props) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const [cert5G, setCert5G] = React.useState<CertWifi5G>();
  const [cert5GBS, setCert5GBS] = React.useState<CertWifi5GBS>();
  const [wifi2g, setWifi2g] = React.useState<CertWifi2G>();
  const [smartHome, setSmartHome] = React.useState<CertWifiSmartHome>();
  const [lan, setLan] = React.useState<Lan>();
  const [network, setNetwork] = React.useState<Internet>();
  const [voIp, setVoIp] = React.useState<CertVoip>();
  const [data, setData] = React.useState<Certificado>();

  const setCertificado = async (id: string,) => {
    const res = await bandaLargaCert(id);
    setData(res);
    const localCert = res.local;
    if (title === "Internet") {
      const internet: Internet = {
        wanDns: localCert.cert_wan_dns,
        wanInet: localCert.cert_wan_iface,
        wanOmci: localCert.cert_info_gpon
      };
      setNetwork(internet);
    } else if (title === "Rede 5GHz") {
      setCert5G(localCert.cert_wifi_5G);
    } else if (title === "Rede 5GHz BS") {
      setCert5GBS(localCert.cert_wifi_5G_BS);
    } else if (title === "Rede 2.4GHz") {
      setWifi2g(localCert.cert_wifi_2G);
    } else if (title === "Smart Home") {
      setSmartHome(localCert.cert_wifi_smartHome);
    } else if (title === "Rede Local") {
      const lan: Lan = {
        lanIface: localCert.cert_lan_iface,
        lanDns: localCert.cert_lan_dns
      };
      setLan(lan);
    } else if (title === "VoIP") {
      setVoIp(localCert.cert_voip);
    }
  };

  React.useEffect(() => {
    if (protocolo) {
      setCertificado(protocolo);
    }
  }, [protocolo]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Detalhes
      </Button>
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Detalhes ${title}`}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            {title === "Internet" && <InternetTable dataRow={network} />}
            {title === "Rede 5GHz" && <Wifi5GPage dataRow={cert5G} />}
            {title === "Rede 5GHz BS" && <Wifi5GBSPage dataRow={cert5GBS} />}
            {title === "Rede 2.4GHz" && <Wifi2GPage dataRow={wifi2g} />}
            {title === "Smart Home" && <SmartHome dataRow={smartHome} />}
            {title === "Rede Local" && <RedeLocal dataRow={lan} />}
            {title === "VoIP" && <VoIp dataRow={voIp} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
